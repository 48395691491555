import { useEffect, useMemo } from 'react';
import { useAppStore } from 'stores/app';
import { useContainerStore } from 'stores/container';
import { useAuthStore } from 'stores/auth';
import { Execution, useExecution, useFlowByExecutionId, useFlowStore } from 'stores/flow';
import { ReportCollectionKey, useReportStore, getReportCollectionKey, pullLastReport } from 'stores/report';
import {
  aggregateMultiSelectReports,
  isEventVisibleByBinding,
  splitCEBridgeId,
  useGlobalFilters,
  useGetCEBridges,
  useGetContainerStaticEvents,
  useGetUiEvent,
  useUiEventStore,
} from 'stores/uiEvent';
import { useVoiceStore } from 'stores/voice';
import { ROUTES } from 'routes/routes.config';
import { toaster } from 'services/toaster';
import { useTranslation } from 'react-i18next';
import { names, useSpy } from 'services/espionage';
import { useForceNavigate } from 'hooks/useForceNavigate';
import { CEBridge, Container, ContainerId, ContainerTypeId, UiEvent, UiEventId } from '@flow/flow-backend-types';
import { ApplicabilityReportValue } from '@jargonic/event-definition-types';

export const useExecutionBlocked = (execution?: Execution) => {
  const { currentUser } = useAuthStore(['currentUser']);
  const navigate = useForceNavigate();

  useEffect(() => {
    const executionStatus = execution?.status;
    const isExecutionInReview = executionStatus === 'inReview';
    const isReviewedByCurrentUser = execution?.reviewedBy?.userId === currentUser?.userId;

    if (isExecutionInReview && isReviewedByCurrentUser) {
      navigate(ROUTES.REVIEW_INSPECTION(execution?.id));
    }
  }, [execution]);
};

export const useStartInspection = (executionId: string) => {
  const execution = useExecution(executionId);
  const flow = useFlowByExecutionId(executionId);
  const navigate = useForceNavigate();
  const { spyMount } = useSpy();
  const { loadRenderModel, setInspectionDataLoading } = useAppStore(['loadRenderModel', 'setInspectionDataLoading']);
  const { loadExecutionReports } = useReportStore(['loadExecutionReports']);
  const { currentExecutionId, setCurrentExecutionId } = useFlowStore(['currentExecutionId', 'setCurrentExecutionId']);
  const { rootContainerIds, containerTemplatesMap, closeAndClear, createDynamicContainer } = useContainerStore([
    'rootContainerIds',
    'containerTemplatesMap',
    'closeAndClear',
    'createDynamicContainer',
  ]);
  const { setFilterValues } = useUiEventStore(['setFilterValues']);
  const { reset: resetVoice } = useVoiceStore(['reset']);

  useEffect(() => {
    (async () => {
      if (execution && !currentExecutionId) {
        setInspectionDataLoading(true);
        const renderModelResponse = await loadRenderModel(execution.flowRef.id, execution.flowRef.version, executionId);

        if (!renderModelResponse) {
          navigate(ROUTES.FLOWS);
          spyMount(names.ExecutionPage.RenderModelError);
          toaster.error({ title: 'Could not load inspection data.', message: null });
          return;
        }
        await loadExecutionReports(executionId);
        setCurrentExecutionId(executionId);
        setInspectionDataLoading(false);
      }
    })();
    return () => {
      closeAndClear();
      setFilterValues([]);
      resetVoice();
    };
  }, [executionId]);

  return {
    flow,
    rootContainerIds,
    execution,
    containerTemplatesMap,
    createDynamicContainer,
  };
};

export const useDynamicContainers = (executionId: string) => {
  const { containerTemplatesMap, createDynamicContainer } = useContainerStore([
    'containerTemplatesMap',
    'createDynamicContainer',
  ]);
  const { t } = useTranslation();

  const containerTemplateIds = Object.keys(containerTemplatesMap);
  const isDynamicContainers = containerTemplateIds.length > 0;
  const hasMultipleDynamicContainers = containerTemplateIds.length > 1;

  const createNewDynamicContainer = async (containerTypeId?: ContainerTypeId) => {
    const newTemplateId = containerTypeId ?? containerTemplateIds[0];
    if (newTemplateId) {
      const isCreated = await createDynamicContainer(newTemplateId, executionId);
      if (!isCreated) toaster.error({ message: t('inspection.errors.dynamicContainerCreationFailure') });
    }
  };

  return {
    isDynamicContainers,
    hasMultipleDynamicContainers,
    createNewDynamicContainer,
  };
};

/**
 * Calculate amount of containers with at least one event which are:
 * - applicable
 * - mandatory
 * - not reported
 */

export function useMissingRequiredEvents() {
  const { reports, validity } = useReportStore(['reports', 'validity']);
  const { containers } = useContainerStore(['containers']);
  const { visibilityBindings } = useUiEventStore(['visibilityBindings']);
  const getContainerStaticEvents = useGetContainerStaticEvents();
  const getUiEvent = useGetUiEvent();
  const getCEBridges = useGetCEBridges();

  const isApplicable = (container: Container) => {
    const { applicabilityEventId } = getContainerStaticEvents(container.id);
    if (!applicabilityEventId) return true;
    const applicabilityReport = pullLastReport(reports, container.id, applicabilityEventId);
    if (!applicabilityReport) return true;
    return applicabilityReport.reportedValue === ApplicabilityReportValue.APPLICABLE;
  };

  const getReportedValue = (containerId: string, event: UiEvent) => {
    const { id: uiEventId, type } = event;
    const reportKey = getReportCollectionKey(containerId, uiEventId);
    return type === 'MultiSelectEvent'
      ? aggregateMultiSelectReports(reports[reportKey] ?? [])
      : pullLastReport(reports, containerId, uiEventId)?.reportedValue;
  };

  const isRequirementFilled = (containerId: ContainerId, uiEventId: UiEventId, isMandatory: boolean) => {
    if (!isMandatory) return true;
    const uiEvent = getUiEvent(uiEventId);
    const value = getReportedValue(containerId, uiEvent);
    return Boolean([value].flat().filter(Boolean).length);
  };

  const isChildTriggered = (ceBridge: CEBridge, container: Container) => {
    const visibilityBinding = visibilityBindings[ceBridge.visibilityBindingIds[0]];
    if (!visibilityBinding) return true;
    const { triggerBridgeId } = visibilityBinding;
    // If container is dynamic, trigger is in the same container
    const [triggerContainerId, triggerEventId] = container.isDynamic
      ? [container.id, splitCEBridgeId(triggerBridgeId)[1]]
      : splitCEBridgeId(triggerBridgeId);

    const reportKey = getReportCollectionKey(triggerContainerId, triggerEventId);
    const triggerValue = getReportedValue(triggerContainerId, getUiEvent(triggerEventId));
    const triggerIsValid = validity[reportKey];
    return isEventVisibleByBinding(visibilityBinding, triggerValue, triggerIsValid);
  };

  return useMemo(() => {
    const withDefault = new Map<ReportCollectionKey, UiEvent>();
    const withoutDefault = new Map<ReportCollectionKey, UiEvent>();

    Object.values(containers).forEach((container) => {
      const applicable = isApplicable(container);
      if (!applicable) return;
      const ceBridges = getCEBridges(container.id, true);

      ceBridges.forEach((ceBridge) => {
        const uiEvent = getUiEvent(ceBridge.uiEventId);
        const key = getReportCollectionKey(container.id, ceBridge.uiEventId);
        const hasDefaultValue = Boolean(uiEvent.defaultValue);

        if (ceBridge.isChild && !isChildTriggered(ceBridge, container)) return;
        if (isRequirementFilled(container.id, ceBridge.uiEventId, ceBridge.isMandatory)) return;

        (hasDefaultValue ? withDefault : withoutDefault).set(key, uiEvent);
      });
    });
    // TODO: Consider performance optimization for deeply nested child events
    return { withDefault, withoutDefault };
  }, [containers, reports, validity, visibilityBindings, getUiEvent, getCEBridges]);
}

export function useOutOfBoundsEvents() {
  const { boundedness } = useReportStore(['boundedness']);
  const { setGlobalFilters } = useGlobalFilters();

  return {
    hasOutOfBoundsItems: Object.values(boundedness).includes(false),
    showOutOfBoundsContainers: () => setGlobalFilters({ outOfBounds: true }),
  };
}
