import { Badge, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { ComponentProps, useMemo } from 'react';
import {
  ListOfValuesElementData,
  ListOfValuesValidation,
  MultiSelectElementData,
  RangeValidation,
  UiEvent,
} from '@flow/flow-backend-types';
import dayjs from 'dayjs';
import {
  getSingleChoiceValueColor,
  getMultiChoiceValueColor,
  validateNumber,
  aggregateMultiSelectReports,
  useValidationByEventId,
} from 'stores/uiEvent';
import { exists } from 'utils';
import { useReportStore, getReportCollectionKey } from 'stores/report';
import { useUserDateFormat } from 'stores/settings';
import { INVALID_COLOR, VALID_COLOR } from 'consts';
import { pullLastReport } from 'stores/report/report.utils';

interface RenderEventValueProps {
  containerId: string;
  uiEvent?: UiEvent;
  notApplicable?: boolean;
}

export const testIds = {
  value: 'render-event-value',
};

export const RenderEventValue = ({ containerId, uiEvent, notApplicable }: RenderEventValueProps) => {
  if (!uiEvent) return null;
  const { t } = useTranslation();
  const dateFormat = useUserDateFormat();
  const { reports } = useReportStore(['reports']);
  const reportKey = getReportCollectionKey(containerId, uiEvent.id);
  const lastReport = pullLastReport(reports, containerId, uiEvent.id);
  const eventValidation = useValidationByEventId(containerId, uiEvent.id);

  const getNumberValidation = (validation?: RangeValidation, reportedValue?: string | boolean) => {
    const numValue = Number(reportedValue);
    const componentProps = { color: validateNumber(numValue, validation) ? VALID_COLOR : INVALID_COLOR };
    return { componentProps, numValue };
  };

  const { badgeValue, badgeProps } = useMemo(() => {
    const reportedValue = lastReport?.reportedValue;
    if (notApplicable)
      return {
        badgeValue: t('inspection.container.notApplicableStatus'),
        badgeProps: { bg: 'transparent', color: 'dark' },
      };
    if (!(uiEvent && reportedValue)) return { badgeValue: null, badgeProps: null };
    let props: ComponentProps<typeof Badge> = {};
    let value: string | undefined;
    switch (uiEvent.type) {
      case 'ButtonsEvent':
      case 'DropdownEvent': {
        const elementData = uiEvent.elementData as ListOfValuesElementData;
        const listValue = elementData.eventValues.find((v) => v.valueName === reportedValue);
        value = listValue?.title ?? listValue?.valueName;
        props = {
          color: getSingleChoiceValueColor(
            eventValidation as ListOfValuesValidation,
            reportedValue,
            listValue?.selectedUIProps?.color,
          ),
        };
        break;
      }
      case 'MultiSelectEvent': {
        const elementData = uiEvent.elementData as MultiSelectElementData;
        const aggregatedValues = aggregateMultiSelectReports(reports[reportKey]);
        const firstValue = aggregatedValues[0];
        const selectedOption = elementData.eventValues.find((el) => el.valueName === firstValue);
        const formattedOption = selectedOption && (selectedOption?.title || selectedOption?.valueName);
        value = aggregatedValues.length > 1 ? `${formattedOption}…` : formattedOption;
        props = {
          color: getMultiChoiceValueColor(
            eventValidation as ListOfValuesValidation,
            aggregatedValues,
            selectedOption?.selectedUIProps?.color,
          ),
        };
        break;
      }
      case 'NumericEvent': {
        const { componentProps, numValue } = getNumberValidation(eventValidation as RangeValidation, reportedValue);
        value = exists(numValue) ? String(numValue) : undefined;
        props = componentProps;
        break;
      }
      case 'DateEvent': {
        const { componentProps, numValue } = getNumberValidation(eventValidation as RangeValidation, reportedValue);
        value = dayjs(numValue).format(dateFormat);
        props = componentProps;
        break;
      }
      default:
        break;
    }

    return { badgeValue: value, badgeProps: props };
  }, [uiEvent, lastReport, notApplicable]);

  return exists(badgeValue) ? (
    <Badge size='sm' h='auto' w='100%' px={6} variant='light' data-testid={testIds.value} {...badgeProps}>
      <Text size='xs' fw={500} tt='initial' ta='center' lineClamp={2} className='break-words whitespace-pre-line'>
        {badgeValue}
      </Text>
    </Badge>
  ) : null;
};
