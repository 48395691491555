import {
  IconMessageDots,
  IconPhotoPlus,
  IconPhotoEdit,
  IconPhotoX,
  IconSquareChevronDown,
  IconSquareChevronUp,
  IconBoxModel2,
  IconSquareNumber2,
  IconCalendarEvent,
  IconXboxX,
  IconCircleCheck,
  IconAlertCircle,
  IconProps,
} from '@tabler/icons-react';
import { ApplicabilityReportValue } from '@jargonic/event-definition-types';
import {
  ButtonsElementData,
  DropdownElementData,
  MultiSelectElementData,
  ReportedValue,
  UiEvent,
  ValueItem,
} from '@flow/flow-backend-types';
import { useTranslation } from 'react-i18next';
import { exists } from 'utils';
import { ImageReportValue } from 'stores/report';
import dayjs from 'dayjs';
import { useUserDateFormat } from 'stores/settings';
import { FC } from 'react';

function getListOfValuesText(values: ValueItem[], reportedValue: string | undefined | null) {
  return values.find((value) => value.valueName === reportedValue)?.title;
}

function getMultiSelectValuesText(values: ValueItem[], reportedValues: string[]) {
  return values
    .filter((value) => reportedValues?.includes(value.valueName))
    .map((value) => value.title ?? value.valueName)
    .join(', ');
}

interface LogItemProps {
  name: string;
  value?: string;
  Icon: FC<IconProps>;
  removed?: boolean;
  error?: boolean;
}

export function useLogItemProps(uiEvent: UiEvent, reportValue?: ReportedValue): LogItemProps {
  const { t } = useTranslation();
  const dateFormat = useUserDateFormat();
  const name = `${uiEvent?.title}: `;
  const value = reportValue;
  const missing = t('inspection.containerLog.eventsText.noReport');
  const removed = !exists(value);

  const noEventProps: LogItemProps = {
    Icon: IconAlertCircle,
    name: t('inspection.containerLog.eventsText.noEvent', { value: reportValue }),
    error: true,
  };

  switch (uiEvent?.type) {
    case 'TextEvent':
    case 'AppendTextEvent':
      return {
        Icon: IconMessageDots,
        name,
        value: reportValue || missing,
        removed: !reportValue,
      };
    case 'DropdownEvent': {
      const { elementData } = uiEvent;
      return {
        Icon: IconSquareChevronDown,
        name,
        value: reportValue
          ? getListOfValuesText((elementData as DropdownElementData).eventValues, reportValue)
          : missing,
        removed,
      };
    }
    case 'ButtonsEvent': {
      const { elementData } = uiEvent;
      return {
        Icon: IconBoxModel2,
        name,
        value: reportValue
          ? getListOfValuesText((elementData as ButtonsElementData).eventValues, reportValue)
          : missing,
        removed,
      };
    }
    case 'NumericEvent':
      return {
        Icon: IconSquareNumber2,
        name,
        value: reportValue || missing,
        removed,
      };
    case 'DateEvent':
      return {
        Icon: IconCalendarEvent,
        name,
        value: reportValue ? dayjs(Number(reportValue)).format(dateFormat) : missing,
        removed,
      };
    case 'ImageEvent': {
      switch (reportValue) {
        case ImageReportValue.CREATE:
          return {
            Icon: IconPhotoPlus,
            name: t('inspection.containerLog.eventsText.imageAdded'),
          };
        case ImageReportValue.EDIT:
          return {
            Icon: IconPhotoEdit,
            name: t('inspection.containerLog.eventsText.imageEdited'),
          };
        case ImageReportValue.DELETE:
          return {
            Icon: IconPhotoX,
            name: t('inspection.containerLog.eventsText.imageDeleted'),
            error: true,
          };
        default:
          return noEventProps;
      }
    }
    case 'MultiSelectEvent': {
      const { add, remove } = JSON.parse(reportValue || '{}');
      const elementData = uiEvent.elementData as MultiSelectElementData;
      if (add?.length) {
        return {
          Icon: IconSquareChevronDown,
          name,
          value: t('inspection.containerLog.eventsText.multiSelect.select', {
            values: getMultiSelectValuesText(elementData.eventValues, add),
          }),
        };
      }
      if (remove?.length) {
        return {
          Icon: IconSquareChevronUp,
          name,
          value: t('inspection.containerLog.eventsText.multiSelect.deselect', {
            values: getMultiSelectValuesText(elementData.eventValues, remove),
          }),
          removed: true,
        };
      }
      return noEventProps;
    }
    case 'ApplicabilityEvent': {
      switch (reportValue) {
        case ApplicabilityReportValue.NOT_APPLICABLE:
          return {
            Icon: IconXboxX,
            name: t('inspection.containerLog.eventsText.notApplicable'),
            removed: true,
          };
        case ApplicabilityReportValue.APPLICABLE:
          return {
            Icon: IconCircleCheck,
            name: t('inspection.containerLog.eventsText.applicable'),
          };
        default:
          return noEventProps;
      }
    }
    default:
      return noEventProps;
  }
}
