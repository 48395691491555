import { useState, useEffect } from 'react';
import { Button, Drawer, Divider, Text, ScrollArea, Stack } from '@mantine/core';
import { UiEventId } from '@flow/flow-backend-types';
import { useTranslation } from 'react-i18next';
import { INSPECTION_PAGE_LANDSCAPE_PERCENTAGE } from 'consts';
import { names, useSpy } from 'services/espionage';
import { useUiEventStore, FilterValue, ValidationFilter, GlobalFilters } from 'stores/uiEvent';
import { useAppStore } from 'stores/app';
import { useSetState } from '@mantine/hooks';
import { EventFilterItem } from './EventFilterItem/EventFilterItem';
import { GlobalFilterItems, testIds as globalFiltersTestIds } from './GlobalFilterItems/GlobalFilterItems';

export const testIds = {
  drawerContainer: 'filters-drawer-container',
  drawerContent: 'filters-drawer-content',
  closeDrawerBtn: 'filters-drawer-close',
  applyFiltersBtn: 'filters-apply',
  resetFiltersBtn: 'filters-reset',
  globalFilters: globalFiltersTestIds,
};

const { LEFT_SECTION_WIDTH } = INSPECTION_PAGE_LANDSCAPE_PERCENTAGE;

interface FiltersDrawerProps {
  open: boolean;
  onClose: () => void;
}

export const FiltersDrawer = ({ open, onClose }: FiltersDrawerProps) => {
  const { t } = useTranslation();
  const { spyMount, spyClick } = useSpy();
  const { filters, setFilterValues, setGlobalFilters } = useUiEventStore([
    'filters',
    'setFilterValues',
    'setGlobalFilters',
  ]);
  const [openFilters, setOpenFilters] = useState<string[]>([]);
  const [values, setValues] = useState<FilterValue[]>([]);
  const [globals, setGlobals] = useSetState<GlobalFilters>(filters.global);
  const { isLandscape } = useAppStore(['isLandscape']);

  const toggleOpenFilter = (eventId: string) => () => {
    setOpenFilters((prevState) =>
      prevState.includes(eventId) ? prevState.filter((id) => id !== eventId) : [...prevState, eventId],
    );
  };

  const aggregateFilterValues = (data: FilterValue[] = []) => {
    if (data.length) {
      return data.reduce<Record<UiEventId, string[]>>((acc, { uiEventId, valueFilters, validationFilters }) => {
        acc[uiEventId] = [...validationFilters, ...valueFilters];
        return acc;
      }, {});
    }
    return [];
  };

  const close = () => {
    spyClick(names.EventFilterDrawer.Close);
    setValues(filters.values);
    onClose();
  };

  const reset = () => {
    spyClick(names.EventFilterDrawer.Reset);
    setValues([]);
    setGlobals({
      outOfBounds: false,
    });
  };

  const apply = () => {
    const prevSelections = aggregateFilterValues(filters.values);
    const currentSelection = aggregateFilterValues(values);
    spyClick(names.EventFilterDrawer.Apply, {
      oldFilterSelection: prevSelections,
      newFilterSelection: currentSelection,
      oldGlobalSelection: filters.global,
      newGlobalSelection: globals,
    });
    setFilterValues(values.filter((filter) => filter.valueFilters.length > 0 || filter.validationFilters.length > 0));
    setGlobalFilters(globals);
    onClose();
  };

  const handleUpdateValues =
    (key: keyof FilterValue, uiEventId: UiEventId, eventFilter?: FilterValue) =>
    (data: (string | ValidationFilter)[]) => {
      setValues((prevValues) =>
        eventFilter
          ? prevValues.map((item) => (item.uiEventId === uiEventId ? { ...item, [key]: data } : item))
          : [
              ...prevValues,
              {
                uiEventId,
                valueFilters: [],
                validationFilters: [],
                [key]: data,
              },
            ],
      );
    };

  const onChangeGlobalFilter = (key: keyof GlobalFilters, value: boolean) => setGlobals({ [key]: value });

  useEffect(() => {
    if (open) spyMount(names.EventFilterDrawer.self);
  }, [open]);

  useEffect(() => {
    setGlobals(filters.global);
    setValues(filters.values);
  }, [filters]);

  return (
    <Drawer.Root
      position={isLandscape ? 'right' : 'bottom'}
      size={isLandscape ? LEFT_SECTION_WIDTH : '90%'}
      opened={open}
      onClose={close}
      data-testid={testIds.drawerContainer}
    >
      <Drawer.Overlay />
      <Drawer.Content className='overflow-hidden' data-testid={testIds.drawerContent}>
        <Drawer.Header>
          <Drawer.CloseButton data-testid={testIds.closeDrawerBtn} ml={0} />
          <Text c='gray.6' size='lg' fw={500}>
            {t('inspection.filter.drawerTitle')}
          </Text>
          <Button
            variant='subtle'
            c='blue.6'
            size='md'
            py='zero'
            h={28}
            fw={500}
            data-testid={testIds.resetFiltersBtn}
            onClick={reset}
          >
            {t('common.reset')}
          </Button>
        </Drawer.Header>
        <Divider size='xs' />
        <Drawer.Body className='flex flex-col' h='calc(100% - 60px)'>
          <ScrollArea h='calc(100% - 40px)' type='scroll'>
            <Stack className='overflow-auto' mt='md'>
              <GlobalFilterItems globalFilters={globals} onChange={onChangeGlobalFilter} />
              <Divider />
              {filters.events.map((filter) => {
                const eventFilter = values.find((item) => item.uiEventId === filter.uiEventId);
                return (
                  <EventFilterItem
                    key={filter.uiEventId}
                    open={openFilters.includes(filter.uiEventId)}
                    filter={filter}
                    filterValues={eventFilter?.valueFilters || []}
                    validationFilterValues={eventFilter?.validationFilters || []}
                    onClick={toggleOpenFilter(filter.uiEventId)}
                    onFilterChanged={handleUpdateValues('valueFilters', filter.uiEventId, eventFilter)}
                    onValidationFilterChanged={handleUpdateValues('validationFilters', filter.uiEventId, eventFilter)}
                  />
                );
              })}
            </Stack>
          </ScrollArea>
          <Divider mb='md' />
          <Button className='shrink-0' size='md' fullWidth onClick={apply} data-testid={testIds.applyFiltersBtn}>
            {t('common.apply')}
          </Button>
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};
