import {
  ExecutionId,
  FlowId,
  Focus,
  ReportedEvent,
  NotificationPayloadType,
  DynamicContainer,
  DynamicPopup,
  NotificationMessage,
  RenderModel,
  PutDynamicContainersRequest,
} from '@flow/flow-backend-types';
import { StateCreator, StoreApi, UseBoundStore, create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { DevtoolsOptions, devtools } from 'zustand/middleware';
import { Hermes, HermesSocket, HermesStatusCode, createStoreHook } from '@aiola/frontend';
import { dynamicModalStore } from 'stores/dynamicModal';
import { flowStore, getIdsAndVersions, Execution, filterJoinableExecutions } from 'stores/flow';
import { reportStore } from 'stores/report';
import { containerStore } from 'stores/container';
import { uiEventStore } from 'stores/uiEvent';
import { useShallow } from 'zustand/react/shallow';
import { config } from 'services/config';
import { focusStore } from 'stores/focus';
import { authStore } from 'stores/auth';
import { db } from 'services/db';
import { networkStore } from 'stores/network';
import { enableMapSet } from 'immer';
import { hermes } from 'services/hermes';
import { modalManager } from 'services/modalManager';
import i18n from 'services/i18n';
import { appApi } from './app.api';
import { DeviceOrientation, FailedActionWithReason } from './app.types';
import {
  updateMetadataAndRenderModel,
  updateLastReports,
  categorizePendingActions,
  invalidateCache,
} from './app.utils';

enableMapSet();
const devtoolsOptions: DevtoolsOptions = {
  name: 'app',
  store: 'app',
  enabled: process.env.NODE_ENV === 'development',
};

type SocketHandlers = { notification: (message: NotificationMessage, callback?: () => void) => void };
interface AppDataSliceState {
  isSyncing: boolean;
  inspectionDataLoading: boolean;
  notificationSocket: HermesSocket<SocketHandlers> | null;
  canNavigate: boolean;
  distractions: Set<string>;
}

interface AppDataSliceActions {
  loadRenderModel: (flowId: FlowId, version: string, flowExecutionId?: ExecutionId) => Promise<boolean>;
  updateCache: (flowId: FlowId, version: string, flowExecutionId?: ExecutionId) => Promise<void>;
  createNotificationSocket: () => void;
  registerHermesHandlers: () => void;
  handleNotification: (message: NotificationMessage) => void;
  closeNotificationSocket: () => void;
  executeCacheSync: () => Promise<void>;
  syncPendingActions: () => Promise<void>;
  revertPendingActions: (failedActions: FailedActionWithReason[]) => Promise<void>;
  setInspectionDataLoading: (loading: boolean) => void;
  setNavigationLock: (locked: boolean) => void;
  addDistraction: (id: string) => void;
  removeDistraction: (id: string) => void;
  /** Delete the DB, invalidate the cookies and log the user out. */
  nukeCurrentSession: () => Promise<void>;
}

interface AppUISliceState {
  currentFlowsTab: number;
  isLandscape: boolean;
}

interface AppUISliceActions {
  setCurrentFlowsTab: (tab: number) => void;
  setDeviceOrientation: (mode: DeviceOrientation) => void;
}

type AppState = AppDataSliceState & AppDataSliceActions & AppUISliceState & AppUISliceActions & { reset: () => void };

const dataSliceInitialState: AppDataSliceState = {
  isSyncing: false,
  inspectionDataLoading: false,
  notificationSocket: null,
  canNavigate: false,
  distractions: new Set(),
};

const appDataSlice: StateCreator<AppState, [['zustand/immer', never]], [], AppDataSliceState & AppDataSliceActions> = (
  set,
  get,
) => ({
  ...dataSliceInitialState,
  createNotificationSocket: () => {
    if (get().notificationSocket) return;

    const socket = Hermes.socket<SocketHandlers>(config.wsHost, `${config.apiPath}/notifications`);

    socket.on('connect', () => {
      console.info(`connected to ${socket.id}`);
    });
    /** Called **whenever** the socket is closed, manually or due to ping.
     * We do not manually close the socket. If this changes, this handler should be updated. */
    socket.on('disconnect', (reason, details) => {
      console.info('disconnected:', reason, details);
    });

    socket.on('connect_error', (err) => {
      console.error('connect_error:', err.message);
    });

    socket.io.on('reconnect_attempt', (attempt) => {
      console.info(`reconnect_attempt ${attempt}`);
    });

    socket.io.on('reconnect', () => {
      console.info('reconnected');
    });

    socket.on('notification', (message, callback) => {
      get().handleNotification(message);
      callback?.();
    });
    set({ notificationSocket: socket });
  },
  registerHermesHandlers: () => {
    hermes.registerHandler('error', (error) => {
      switch (error.status) {
        case HermesStatusCode.Unauthorized:
          authStore.getState().logout();
          break;
        case HermesStatusCode.Forbidden:
          authStore.getState().createSession();
          break;
        default:
          break;
      }
    });
  },
  closeNotificationSocket: () => {
    get().notificationSocket?.disconnect();
    set({ notificationSocket: null });
  },
  loadRenderModel: async (flowId, version, flowExecutionId) => {
    const { online } = networkStore.getState();
    let renderModel: RenderModel | undefined;
    if (online) {
      renderModel = await appApi.fetchRenderModel(flowId, version, flowExecutionId);
      if (renderModel) await db.storeRenderModel(renderModel, flowExecutionId);
    }

    if (!renderModel) {
      renderModel = await db.getRenderModel({ id: flowId, version }, flowExecutionId);
    }

    if (!renderModel) return false;

    const {
      containers,
      uiEvents,
      rootContainerIds,
      validations,
      containerTemplatesMap,
      containerEventsMap,
      visibilityBindings,
    } = renderModel;

    const { setContainersData } = containerStore.getState();
    const { setUIEvents, setValidations, setVisibilityBindings } = uiEventStore.getState();

    setContainersData(rootContainerIds, containers, containerEventsMap, containerTemplatesMap);
    setUIEvents(uiEvents);
    setValidations(validations);
    setVisibilityBindings(visibilityBindings);
    return true;
  },

  handleNotification: (message: NotificationMessage) => {
    const { sessionId } = authStore.getState();
    const isCurrentSession = sessionId === message.sessionId;
    if (Number(message.metadata?.retryCount) > 0) return;
    switch (message.payloadType as NotificationPayloadType) {
      case 'ExecutionStarted':
      case 'ExecutionJoined':
      case 'ExecutionInReview':
      case 'ExecutionContinue':
      case 'ExecutionExpired':
      case 'ExecutionCancelled':
      case 'ExecutionFinished':
        flowStore.getState().handleExecutionNotification(message.payload as Execution, isCurrentSession);
        break;
      case 'NewEventsReport':
        reportStore.getState().receive(message.payload as ReportedEvent[]);
        break;
      case 'SetFocus': {
        if (config.enableFocusAnimations) {
          focusStore.getState().onFocusUpdate(message.payload as Focus, (message as NotificationMessage).sessionId);
        }
        break;
      }
      case 'NewContainersCreated': {
        const newDynamicContainers = message.payload as DynamicContainer[];
        newDynamicContainers.forEach((newDynamicContainer) =>
          containerStore.getState().onContainerAdded(newDynamicContainer),
        );
        break;
      }
      case 'NewDynamicPopup':
        dynamicModalStore.getState().setLayout(message.payload as DynamicPopup);
        break;
      case 'Nuke': {
        const shouldNuke = (message.payload as string) === sessionId;
        if (!shouldNuke) return;
        get().nukeCurrentSession();
        break;
      }
      default:
        break;
    }
  },
  updateCache: async (flowId, version, flowExecutionId) => {
    const renderModel = await appApi.fetchRenderModel(flowId, version, flowExecutionId);
    if (renderModel) await db.storeRenderModel(renderModel, flowExecutionId);
  },
  executeCacheSync: async () => {
    if (get().isSyncing) return;

    set({ isSyncing: true });

    const {
      fetchFlowsAndExecutions,
      loadFlowsAndExecutions,
      updateCache: updateFlowsCache,
      currentExecutionId,
    } = flowStore.getState();
    try {
      const { userId } = authStore.getState().currentUser!;

      const [serverFlows, serverExecutions] = await fetchFlowsAndExecutions();

      const joinableExecutions = filterJoinableExecutions(serverExecutions, serverFlows, userId);
      const idsAndVersions = getIdsAndVersions(serverFlows, joinableExecutions);

      await Promise.allSettled([
        invalidateCache(serverFlows, serverExecutions, currentExecutionId),
        updateFlowsCache(serverFlows, serverExecutions),
        updateMetadataAndRenderModel(idsAndVersions),
        updateLastReports(joinableExecutions),
      ]);
    } catch (error) {
      console.log(error);
    } finally {
      loadFlowsAndExecutions();
      set({ isSyncing: false });
    }
  },
  syncPendingActions: async () => {
    const pendingActions = await db.listPendingActions();

    if (pendingActions.length === 0) return;

    try {
      const { failed } = await appApi.syncPendingActions({ items: pendingActions });
      const { failedActionsWithReason, actionIdsToClear } = categorizePendingActions(pendingActions, failed);
      await db.clearPendingActions(actionIdsToClear);
      await get().revertPendingActions(failedActionsWithReason);
    } catch (error) {
      console.error(error);
    }
  },
  revertPendingActions: async (failedActionsWithReason) => {
    for (const { action } of failedActionsWithReason) {
      switch (action.type) {
        case 'putDynamicContainers': {
          const { dynamicContainers } = action.payload as PutDynamicContainersRequest;
          dynamicContainers.forEach((dynamicContainer) => {
            containerStore.getState().removeContainer(dynamicContainer.id ?? '');
          });
          break;
        }
        default:
          break;
      }
    }
  },
  setInspectionDataLoading: (loading) => set({ inspectionDataLoading: loading }),
  setNavigationLock: (locked) => set({ canNavigate: !locked }),
  addDistraction: (id) => {
    set((state) => {
      state.distractions.add(id);
    });
  },
  removeDistraction: (id) => {
    set((state) => {
      state.distractions.delete(id);
    });
  },
  nukeCurrentSession: async () => {
    modalManager.custom({
      withCloseButton: false,
      closeOnClickOutside: false,
      centered: true,
      children: i18n.t('nuke.message'),
    });
    setTimeout(async () => {
      await db.delete();
      await authStore.getState().logout();
      window.location.reload();
    }, 5000);
  },
});

const uiSliceInitialState: AppUISliceState = {
  currentFlowsTab: 0,
  isLandscape: false,
};

const appUISlice: StateCreator<AppState, [['zustand/immer', never]], [], AppUISliceState & AppUISliceActions> = (
  set,
) => ({
  ...uiSliceInitialState,
  setDeviceOrientation: (mode: DeviceOrientation) => set({ isLandscape: mode === DeviceOrientation.Landscape }),
  setCurrentFlowsTab: (tab: number) => set({ currentFlowsTab: tab }),
});

export const appStore = create(
  devtools(
    immer<AppState>((...args) => ({
      ...appDataSlice(...args),
      ...appUISlice(...args),
      reset: () => {
        const [set] = args;
        set({ ...dataSliceInitialState, ...uiSliceInitialState });
      },
    })),
    devtoolsOptions,
  ),
);

export const useAppStore = createStoreHook<AppState>({
  store: appStore as UseBoundStore<StoreApi<AppState>>,
  useShallow,
});
