import {
  UiEventId,
  DropdownElementData,
  NumericElementData,
  ButtonsElementData,
  DateElementData,
  TextElementData,
  UiEvent,
  ImageElementData,
  ApplicabilityElementData,
  MultiSelectElementData,
  ValueItem,
  VisibilityBinding,
  VisibilityBindingId,
  ContainerId,
} from '@flow/flow-backend-types';
import { ReportedEventsMap, ReportValidityMap } from 'stores/report';

// CLIENT INTERFACES
export interface ButtonsEventData extends UiEvent {
  type: 'ButtonsEvent';
  elementData: ButtonsElementData;
}

export interface DropdownEventData extends UiEvent {
  type: 'DropdownEvent';
  elementData: DropdownElementData;
}

export interface TextEventData extends UiEvent {
  type: 'TextEvent';
  elementData: TextElementData;
}

export interface NumericEventData extends UiEvent {
  type: 'NumericEvent';
  elementData: NumericElementData;
}

export interface DateEventData extends UiEvent {
  type: 'DateEvent';
  elementData: DateElementData;
}

export interface ImageEventData extends UiEvent {
  type: 'ImageEvent';
  elementData: ImageElementData;
}

export interface ApplicabilityEventData extends UiEvent {
  type: 'ApplicabilityEvent';
  elementData: ApplicabilityElementData;
}

export interface MultiSelectEventData extends UiEvent {
  type: 'MultiSelectEvent';
  elementData: MultiSelectElementData;
}

export interface AppendTextEventData extends UiEvent {
  type: 'AppendTextEvent';
  elementData: TextElementData;
}

export type UiEventData =
  | ButtonsEventData
  | DropdownEventData
  | TextEventData
  | NumericEventData
  | DateEventData
  | MultiSelectEventData
  | ImageEventData
  | ApplicabilityEventData
  | AppendTextEventData;

export interface ReportedImage {
  id: string;
  original: string;
  edited?: string;
}

export enum ValidationFilter {
  Valid = 'valid',
  Invalid = 'invalid',
  Checked = 'checked',
  Unchecked = 'unchecked',
}

export interface GlobalFilters {
  /** Filter containers that include events that are out of bounds. */
  outOfBounds: boolean;
}

export interface EventFilter {
  uiEventId: UiEventId;
  title?: string;
  values: ValueItem[];
  hasValidations: boolean;
}

export interface FilterValue {
  uiEventId: UiEventId;
  valueFilters: string[];
  validationFilters: ValidationFilter[];
}

export type UIEventsMap = Record<UiEventId, UiEvent>;
export type VisibilityBindingsMap = Record<VisibilityBindingId, VisibilityBinding>;

export interface IsChildEventVisibleParams {
  visibilityBindings: VisibilityBindingsMap;
  visibilityBindingIds: VisibilityBindingId[];
  reports: ReportedEventsMap;
  containerId: ContainerId;
  uiEvents: UiEvent[];
  validity: ReportValidityMap;
}
